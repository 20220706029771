import { ImageTheme } from '@/components/Image/theme';
import { tw } from '@/utils/tw';
import BaseArticleTeaserDefaultTheme from 'base/components/ArticleTeaser/Default/theme';

const Image = tw.theme({
  extend: ImageTheme,
  base: `aspect-default`,
});

const ArticleTeaserDefault = tw.theme({
  extend: BaseArticleTeaserDefaultTheme,
  slots: {
    caption: `uppercase text-logga-700 text-category-md`,
    description: `text-body-md`,
  },
  variants: {
    variant: {
      horizontal: {
        description: `sm:container-col-sm-7:mt-3 lg:container-col-sm-7:text-body-lg`,
        headline: `text-headline-xs sm:container-col-lg-7:text-headline-xs md:text-headline-md`,
      },
      vertical: {
        base: `w-full auto-rows-min gap-3 sm:container-col-lg-4:mb-5`,
        description: `mt-1 lg:container-col-sm-7:text-body-lg`,
        headline: `text-headline-sm container-col-lg-12:text-headline-sm sm:container-col-lg-4:text-headline-sm sm:container-col-lg-7:text-headline-md sm:container-col-lg-12:text-headline-md md:container-col-lg-7:text-headline-lg md:container-col-lg-12:text-headline-lg lg:container-col-lg-7:text-headline-lg`,
      },
    },
  },
});

export default Object.assign(ArticleTeaserDefault, { Image });
