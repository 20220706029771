import { ImageProps } from '@/components/Image';
import { OptionalLink, OptionalLinkProps } from '@/components/OptionalLink';
import type { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ContentProps } from '@/types/content';
import type { ReactNode } from 'react';
import { ArticleTeaser } from './ArticleTeaser';

export const getCategoryUrl = (data: ContentProps['data'], meta: ContentProps['meta']) => {
  const { category, categoryUrl, prominentTag } = data ?? {};
  const { isNativeAd, nativeAdLabel } = meta ?? {};

  if (isNativeAd) {
    return `etikett/${(nativeAdLabel || ('' as string)).toLocaleLowerCase()}`;
  }

  if (prominentTag) {
    return `etikett/${prominentTag}`;
  }

  return categoryUrl || category;
};

export interface StandaloneArticleTeaserDefaultProps extends StandaloneComponentProps<typeof ArticleTeaser> {
  caption?: ReactNode;
  description?: ReactNode;
  footer?: ReactNode;
  header?: ReactNode;
  headline?: ReactNode;
  kicker?: ReactNode;
  links?: {
    article?: OptionalLinkProps;
    caption?: OptionalLinkProps;
  };
  image: ImageProps;
}

export const StandaloneArticleTeaserDefault: StandaloneComponent<StandaloneArticleTeaserDefaultProps> = ({
  caption,
  description,
  footer,
  header,
  headline,
  image,
  kicker,
  links,
  ...props
}) => {
  return (
    <ArticleTeaser {...props}>
      {header && <ArticleTeaser.Header>{header}</ArticleTeaser.Header>}
      <OptionalLink {...links?.article} content={<ArticleTeaser.Image {...image} options={props?.options?.$image} />} />
      <ArticleTeaser.Group>
        {caption && (
          <OptionalLink {...links?.caption} content={<ArticleTeaser.Caption>{caption}</ArticleTeaser.Caption>} />
        )}
        {headline && (
          <OptionalLink {...links?.article} content={<ArticleTeaser.Headline>{headline}</ArticleTeaser.Headline>} />
        )}
        {description && <ArticleTeaser.Description>{description}</ArticleTeaser.Description>}
        {kicker && <ArticleTeaser.Description>{kicker}</ArticleTeaser.Description>}
      </ArticleTeaser.Group>
      {footer && <ArticleTeaser.Footer>{footer}</ArticleTeaser.Footer>}
    </ArticleTeaser>
  );
};
